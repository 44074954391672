import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box,
  CircularProgress, 
  Typography,
  Alert,
  Backdrop
} from '@mui/material';
import Navbar from './Navbar/Navbar';
import Sidebar from './Sidebar/Sidebar';
import { getJobs } from '../services/jobService';
import { AuthContext } from '../context/authContext';

function MainLayout({ children }) {
  const [jobs, setJobs] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loadingState, setLoadingState] = useState({
    isLoading: true,
    error: null
  });

  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      setLoadingState({ isLoading: true, error: null });
      const data = await getJobs();
      setJobs(data);
    } catch (error) {
      console.error('Failed to fetch jobs:', error);
      setLoadingState({ 
        isLoading: false, 
        error: 'Failed to load jobs. Please try again later.'
      });
    } finally {
      setLoadingState(state => ({ ...state, isLoading: false }));
    }
  };

  const handleJobClick = (job) => {
    navigate(`/validate/${job.id}`);
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(prev => !prev);
  };

  const renderContent = () => {
    if (loadingState.isLoading) {
      return (
        <Backdrop open sx={{ color: '#fff', zIndex: 1000 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }

    if (loadingState.error) {
      return (
        <Box sx={{ p: 3 }}>
          <Alert severity="error">{loadingState.error}</Alert>
        </Box>
      );
    }

    return (
      <>
        {isAuthenticated && (
          <Sidebar
            open={sidebarOpen}
            onClose={() => setSidebarOpen(false)}
            jobs={jobs}
            handleJobClick={handleJobClick}
          />
        )}
        <Box 
          component="main" 
          sx={{
            flexGrow: 1,
            p: 3,
            transition: 'margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
            marginLeft: sidebarOpen ? '240px' : 0
          }}
        >
          {children}
        </Box>
      </>
    );
  };

  return (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      <Navbar toggleSidebar={handleSidebarToggle} />
      <Box 
        sx={{ 
          display: 'flex',
          flexGrow: 1,
          mt: '64px' // Height of navbar
        }}
      >
        {renderContent()}
      </Box>
    </Box>
  );
}

export default MainLayout;