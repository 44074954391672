import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import FilePreviewDialog from './FilePreviewDialog';
import { getUploadPolicy, uploadToGCS } from '../../services/uploadService';

const PromptInput = ({ onSubmit }) => {
  const [prompt, setPrompt] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileForPreview, setFileForPreview] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedExtensions = ['png', 'jpg', 'jpeg', 'gif', 'pdf', 'doc', 'docx'];
      const maxFileSize = 200 * 1024 * 1024;

      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        setError('File type not allowed');
        setSnackbarOpen(true);
        return;
      }
      if (file.size > maxFileSize) {
        setError('File size exceeds the 200 MB limit');
        setSnackbarOpen(true);
        return;
      }

      setFileForPreview(file);
      setIsDialogOpen(true);
    }
  };

  const handleConfirm = () => {
    setSelectedFile(fileForPreview);
    setIsDialogOpen(false);
    setFileForPreview(null);
  };

  const handleCancel = () => {
    setFileForPreview(null);
    setIsDialogOpen(false);
    setSelectedFile(null);
  };

  const truncateFileName = (fileName, maxLength = 20) => {
    if (!fileName) return 'Upload your plan';
    if (fileName.length <= maxLength) return fileName;
    return fileName.substring(0, maxLength - 3) + '...';
  };

  const handleSubmit = async () => {
    if (!prompt) {
      setError('Please enter a prompt');
      setSnackbarOpen(true);
      return;
    }

    if (!selectedFile) {
      setError('Please upload a file');
      setSnackbarOpen(true);
      return;
    }

    setUploading(true);
    setError('');

    try {
      const policy = await getUploadPolicy(selectedFile.name, selectedFile.type);
      const gcsUrl = await uploadToGCS(policy, selectedFile);
      await onSubmit(prompt, gcsUrl);
      setPrompt('');
      setSelectedFile(null);
    } catch (err) {
      console.error('Upload error:', err);
      setError('Failed to upload file. Please try again.');
      setSnackbarOpen(true);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box sx={{ textAlign: 'center', padding: { xs: '10px', sm: '20px' } }}>
      <Box
        component={Paper}
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
          p: { xs: 2, sm: 3 },
          width: { xs: '95%', sm: '80%' },
          height: 500,
          maxWidth: 600,
          borderRadius: 2,
          mx: 'auto',
          position: 'relative',
        }}
      >
        <TextField
          placeholder="Example Prompt: Produce a complete takeoff for this home."
          multiline
          rows={20}
          variant="outlined"
          fullWidth
          required
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          sx={{
            height: '100%',
            '& .MuiOutlinedInput-root': {
              bgcolor: 'white',
              '& fieldset': {
                borderColor: '#b48478',
              },
              '&:hover fieldset': {
                borderColor: '#b48478',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#b48478',
              },
            },
          }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '50%',
            ml: 2,
          }}
        >
          <Box sx={{ mb: 3 }}>
            <input
              accept=".png,.jpg,.jpeg,.gif,.pdf,.doc,.docx"
              style={{ display: 'none' }}
              id="upload-file-input"
              type="file"
              onChange={handleFileChange}
              required
            />
            <label htmlFor="upload-file-input">
              <IconButton sx={{ color: '#1a1a8c' }} component="span">
                <AttachFile sx={{ fontSize: 40 }} />
              </IconButton>
            </label>
            <Typography
              sx={{
                color: 'Black',
                fontSize: 16,
                fontWeight: 'medium',
                mt: 2,
              }}
            >
              {selectedFile ? truncateFileName(selectedFile.name) : 'Upload your plan'}
            </Typography>
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={uploading}
          >
            {uploading ? (
              <>
                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                Uploading...
              </>
            ) : (
              'Submit'
            )}
          </Button>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          mt: { xs: 2, sm: 4 },
          width: { xs: '60%', sm: '50%', md: '30%' },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      {fileForPreview && (
        <FilePreviewDialog
          open={isDialogOpen}
          file={fileForPreview}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </Box>
  );
};

export default PromptInput;
