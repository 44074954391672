// src/components/ChatDashboard/ChatDashboard.jsx

import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle   } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  getJobs,
  createJob,
} from '../../services/jobService';
import PromptInput from './PromptInput';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Navbar/Navbar';
import AlertMessage from '../AlertMessage';

import '../../styles/ChatDashboard.css';
import { AuthContext } from '../../context/authContext';

import MainLayout from '../MainLayout';

function ChatDashboard() {
  const [jobs, setJobs] = useState([]);
  const [currentJob, setCurrentJob] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isCreatingJob, setIsCreatingJob] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  

  useEffect(() => {
    if (isAuthenticated) {
      fetchJobs();
    } else {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const fetchJobs = async () => {
    try {
      const data = await getJobs();
      setJobs(data);
    } catch (error) {
      console.error('Failed to fetch jobs:', error);
      setError('Failed to load jobs. Please try again later.');
    }
  };

  const handleJobClick = async (job) => {
    console.log('Selected job:', job);
    navigate(`/validate/${job.id}`);

    // navigate('/validate', { state: { jobId: job.id } });
    // navigate('/thank-you');

  };

  const handlePromptSubmit = async (promptInput, gcsUrl) => {
    // console.log('Prompt:', promptInput);
    // console.log('GCS URL:', gcsUrl);
    if (!promptInput.trim() || !gcsUrl) {
      console.error('Please enter a prompt and upload a file.');
      setError('Please enter a prompt and upload a file.');
      return;
    }
  
    try {
      // const payload = {
      //   prompt: promptInput,
      //   file_url: gcsUrl,
      // };
      
      // Log to Cloud Run
      // console.log('Creating job with payload:', payload);

      alert('Thank you for uploading the file')

      // Create the job
      const newJob = await createJob(prompt, gcsUrl);
  
      if (newJob.error) {
        // Display the error message from the server
        setError(newJob.error);
      } else {      
      setJobs([newJob, ...jobs]);
      setCurrentJob(newJob);
      navigate(`/validate/${newJob.id}`);
      }
      // navigate('/validate', { state: { jobId: newJob.id } });
      // navigate('/thank-you');

    } catch (error) {
      console.error('Failed to create job:', error);
      let errorMessage = 'Failed to create job. Please try again.';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      setError(errorMessage);
    }
  };

  return (
    <MainLayout>

    <div className="chat-container">
      {/* Navbar */}
      {/* <Navbar toggleSidebar={toggleSidebar} /> */}
      {/* <Navbar /> */}

      {/* Sidebar */}
      {/* <Sidebar
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        jobs={jobs}
        currentJob={currentJob}
        handleJobClick={handleJobClick}
        // handleNewJob={handleNewJob}
        // handleRenameJob={handleRenameJob}
      /> */}
      {/* Main Chat Area */}
      <div className="chat-main">

      <Dialog
          open={Boolean(error)}
          onClose={() => setError('')}
          aria-labelledby="error-dialog-title"
          aria-describedby="error-dialog-description"
        >
          <DialogTitle id="error-dialog-title" sx={{ color: 'error.main' }}>
            Error
          </DialogTitle>
          <DialogContent>
            <Typography id="error-dialog-description">{error}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setError('')} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>



          {isCreatingJob ? (
          // Display the initial content when creating a new job
          <PromptInput onSubmit={handlePromptSubmit} />
        ) : currentJob ? (
          // Display the messages for the selected job
          <>
            {/* Messages */}
            <div className="chat-messages">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`chat-message ${
                    message.sender === 'system' ? 'chat-message-system' : 'chat-message-user'
                  }`}
                >
                  <div className="chat-message-content">
                    <Typography variant="body1">{message.content}</Typography>
                    {message.file_url && (
                      <a href={message.file_url} target="_blank" rel="noopener noreferrer">
                        Attached File
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Input Area */}
            <div className="chat-input-area">
              <textarea
                className="chat-input"
                placeholder="Send a message..."
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                rows={1}
              />
              <div className="chat-input-actions">
                <Button
                  variant="contained"
                  className="send-button"
                  endIcon={<SendIcon />}
                  // onClick={handleMessageSubmit}
                >
                  Send
                </Button>
              </div>
            </div>
          </>
        ) : (
          // If no job is selected, display the PromptInput for a new job
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <PromptInput onSubmit={handlePromptSubmit} />
          </div>
        )}
      </div>

    </div>
  
    </MainLayout>

  );
}

export default ChatDashboard;
