import React, { useState, useMemo, useRef, useEffect } from 'react';
import { 
  Drawer, 
  List, 
  ListItem, 
  ListItemText, 
  Divider, 
  IconButton,
  Typography,
  Box,
  Button,
  ListItemIcon,
  Avatar,
  TextField,
  InputAdornment,
  useMediaQuery,
  Collapse
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import FolderIcon from '@mui/icons-material/Folder';
import { useNavigate } from 'react-router-dom';

function Sidebar({
  open,
  onClose,
  jobs = [],
  currentJob,
  handleJobClick,
}) {
  const navigate = useNavigate();
  const isPermanent = useMediaQuery('(min-width:769px)');
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const searchInputRef = useRef(null);

  // Navigate to the New Job page
  const handleNewJobPage = () => {
    navigate('/chat');
  };

  // Toggle search input visibility
  const toggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
  };

  // Toggle filter functionality (Placeholder)
  const toggleFilter = () => {
    setIsFilterOpen((prev) => !prev);
    // Implement filter logic as needed
  };

  // Focus on the search input when it becomes visible
  useEffect(() => {
    if (isSearchOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isSearchOpen]);

  // Filter jobs based on search term
  const filteredJobs = useMemo(() => {
    return jobs.filter((job) =>
      job.job_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [jobs, searchTerm]);

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      anchor="left"
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        '& .MuiDrawer-paper': {
          width: 280, // Fixed width to maintain sidebar size
          boxSizing: 'border-box',
          background: 'linear-gradient(180deg, #f8f9fa 0%, #ffffff 100%)',
          borderRight: '1px solid rgba(0, 0, 0, 0.08)',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {/* Header with New Job button and Icons */}
        <Box
          sx={{
            p: 2,
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
            background: 'white',
            boxShadow: '0 2px 4px rgba(0,0,0,0.02)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNewJobPage}
            sx={{
              py: 1,
              backgroundColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
              borderRadius: '8px',
              textTransform: 'none',
              fontSize: '0.95rem',
              fontWeight: 500,
              minWidth: 100, // Ensure consistent button size
            }}
          >
            New Job
          </Button>
          <Box>
            {/* Search Icon or Close Icon */}
            <IconButton 
              aria-label={isSearchOpen ? "Close search" : "Search jobs"} 
              onClick={toggleSearch}
              sx={{ ml: 1 }}
            >
              {isSearchOpen ? <CloseIcon /> : <SearchIcon />}
            </IconButton>
            {/* Filter Icon */}
            <IconButton 
              aria-label="Filter jobs" 
              onClick={toggleFilter}
              sx={{ ml: 1 }}
            >
              <FilterListIcon />
            </IconButton>
          </Box>
        </Box>

        {/* Search Input Field */}
        <Collapse in={isSearchOpen} timeout="auto" unmountOnExit>
          <Box sx={{ p: 2, borderBottom: '1px solid rgba(0, 0, 0, 0.08)' }}>
            <TextField
              variant="outlined"
              placeholder="Search jobs..."
              fullWidth
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              inputRef={searchInputRef}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment: (
                  searchTerm && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Clear search"
                        onClick={() => setSearchTerm('')}
                        edge="end"
                        size="small"
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                ),
                sx: {
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  '& fieldset': {
                    borderColor: 'rgba(0,0,0,0.08)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0,0,0,0.15)',
                  },
                },
              }}
            />
          </Box>
        </Collapse>

        {/* Optional Filter Section */}
        <Collapse in={isFilterOpen} timeout="auto" unmountOnExit>
          <Box sx={{ p: 2, borderBottom: '1px solid rgba(0, 0, 0, 0.08)' }}>
            {/* Implement your filter options here */}
            <Typography variant="subtitle1" gutterBottom>
              Filter Jobs
            </Typography>
            {/* Example Filter Option */}
            <Button
              variant="outlined"
              fullWidth
              startIcon={<FilterListIcon />}
              onClick={() => {
                // Placeholder for filter action
                console.log('Filter action');
              }}
              sx={{
                textTransform: 'none',
                fontSize: '0.9rem',
                fontWeight: 400,
                borderRadius: '8px',
              }}
            >
              Example Filter
            </Button>
          </Box>
        </Collapse>

        {/* Jobs List */}
        <List sx={{ flexGrow: 1, overflowY: 'auto', px: 1, py: 2 }}>
          {filteredJobs.length > 0 ? (
            filteredJobs.map((job, index) => (
              <React.Fragment key={job.id}>
                <ListItem
                  button
                  selected={currentJob && currentJob.id === job.id}
                  onClick={() => {
                    handleJobClick(job);
                    if (!isPermanent) onClose();
                  }}
                  sx={{
                    mb: 0.5,
                    borderRadius: '8px',
                    '&.Mui-selected': {
                      backgroundColor: 'primary.lighter',
                      '&:hover': {
                        backgroundColor: 'primary.lighter',
                      },
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {job.job_name || `Job ${job.id}`}
                      </Typography>
                    }
                  />
                </ListItem>
                {index < filteredJobs.length - 1 && (
                  <Divider sx={{ my: 1, borderColor: 'rgba(0,0,0,0.04)' }} />
                )}
              </React.Fragment>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 4 }}>
              No jobs found.
            </Typography>
          )}
        </List>
      </Box>
    </Drawer>
  );
}

export default Sidebar;
