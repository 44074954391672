// src/App.js

import React, { useState, useEffect, useContext } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@mui/material'; // Import useMediaQuery

import Sidebar from './components/Sidebar/Sidebar'; // Import Sidebar
import Login from './components/Authentication/Login';
import Signup from './components/Authentication/Signup';
import NotFound from './components/NotFound';
import ChatDashboard from './components/ChatDashboard/ChatDashboard';
import ThankYouPage from './components/ChatDashboard/ThankYouPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import JobCreationPage from './components/Jobs/JobCreationPage';

// import Home from './components/Home';

import Profile from './components/Profile/Profile';

import ValidationOutput from './components/Validation/ValidationOutput';


import PrivateRoute from './utils/PrivateRoute';
import { setupAxiosInterceptors } from './utils/axiosInterceptor';
import { AuthProvider,AuthContext } from './context/authContext'; // Import AuthContext

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCSRF } from './hooks/useCSRF';

const AppContent = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext); // Access authentication status

  const isMobile = useMediaQuery('(max-width:768px)');


  useEffect(() => {
    setupAxiosInterceptors(navigate);
  }, [navigate]);




  return (
    <div style={{ display: 'flex' }}>
      {/* {isAuthenticated && !isMobile && <Sidebar />} */}
      <div style={{ 
        marginTop: { xs: '56px', sm: '64px' },
        width: '100%'
      }}>
        
        <Routes>
        <Route path="/privacy" element={<PrivacyPolicy />} />
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Signup />} />

          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/chat" element={<PrivateRoute> <ChatDashboard /> </PrivateRoute>}/>
          <Route path="/thank-you" element={<PrivateRoute> <ThankYouPage /> </PrivateRoute> } />
          <Route path="/profile" element={<PrivateRoute> <Profile /> </PrivateRoute>} />
          <Route path="/create-job" element={<PrivateRoute> <JobCreationPage /> </PrivateRoute>} />

          {/* <Route path="/validate/:projectId/:fileId" element={<PrivateRoute><ValidateFile /></PrivateRoute>} />
          <Route path="/jobs/:jobId/details" element={<PrivateRoute><JobDetailsForm /></PrivateRoute>} />
          <Route path="/jobs/:jobId/takeoff" element={<PrivateRoute><TakeoffPage /></PrivateRoute>} /> */}

          <Route path="/validate/:jobId" element={<PrivateRoute> <ValidationOutput /> </PrivateRoute>} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <ToastContainer />
    </div>
  );
};

const App = () => {
  useCSRF(); // Initialize CSRF token

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;