import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableContainer,
  Paper,
  Toolbar,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Close as CloseIcon,
  Send as SendIcon,
  Chat as ChatIcon,
  DragHandle as DragHandleIcon,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';

import SnapshotImage from '../../static/Snapshot.png'; // Ensure this path is correct
import MainLayout from '../MainLayout';
import { createJob, getTakeoffData } from '../../services/jobService'; // Import the new service call

// Memoized Message Components
const MessageLeft = React.memo(function MessageLeft({ message, timestamp, avatarDisp }) {
  return (
    <Box sx={{ display: 'flex', mb: 1 }}>
      {avatarDisp && <Avatar sx={{ mr: 1 }}>A</Avatar>}
      <Paper sx={{ p: 1, backgroundColor: 'grey.200', maxWidth: '70%' }}>
        <Typography variant="body2">{message}</Typography>
        {timestamp && (
          <Typography variant="caption" sx={{ display: 'block', textAlign: 'right', mt: 0.5 }}>
            {timestamp}
          </Typography>
        )}
      </Paper>
    </Box>
  );
});

const MessageRight = React.memo(function MessageRight({ message, timestamp, avatarDisp }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
      <Paper sx={{ p: 1, backgroundColor: 'primary.light', maxWidth: '70%' }}>
        <Typography variant="body2" sx={{ color: 'white' }}>
          {message}
        </Typography>
        {timestamp && (
          <Typography
            variant="caption"
            sx={{ display: 'block', textAlign: 'right', mt: 0.5, color: 'white' }}
          >
            {timestamp}
          </Typography>
        )}
      </Paper>
      {avatarDisp && <Avatar sx={{ ml: 1, bgcolor: 'primary.main' }}>U</Avatar>}
    </Box>
  );
});

// Snapshot Preview Component
function SnapshotPreview({ onOpen }) {
  return (
    <Box
      sx={{
        position: 'relative',
        border: '1px solid #ccc',
        borderRadius: 2,
        overflow: 'hidden',
        cursor: 'pointer',
        boxShadow: 3,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        height: '100%', // Ensure it fills its container
      }}
      onClick={onOpen}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          component="img"
          src={SnapshotImage}
          alt="Snapshot Preview"
          sx={{
            maxWidth: '100%',
            maxHeight: '100%',
            display: 'block',
          }}
        />
      </Box>
    </Box>
  );
}

// Chat Preview Component
function ChatPreview({ onOpen }) {
  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: 2,
        backgroundColor: 'white',
        boxShadow: 3,
        overflow: 'hidden',
        cursor: 'pointer',
        p: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        height: '100%', // Ensure it fills its container
      }}
      onClick={onOpen}
    >
      <Avatar sx={{ bgcolor: 'primary.main' }}>AI</Avatar>
      <Box sx={{ flex: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          AI Assistant
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Hello, how can I assist you today?
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          borderRadius: '50%',
          p: 1,
          boxShadow: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ChatIcon sx={{ color: 'white', fontSize: 20 }} />
      </Box>
    </Box>
  );
}

// TextInput Component for Chat
function TextInput({ value, onChange, onSend }) {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSend();
    }
  };

  return (
    <Box sx={{ display: 'flex', p: 1, borderTop: '1px solid #ccc' }}>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        placeholder="Enter your prompt..."
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
        sx={{ mr: 1 }}
      />
      <IconButton color="primary" onClick={onSend}>
        <SendIcon />
      </IconButton>
    </Box>
  );
}

// Memoized SubdivisionRow
const SubdivisionRow = React.memo(function SubdivisionRow({
  editMode,
  subdivision,
  subKey,
  divisionKey,
  handleSubdivisionChange,
  handleDeleteSubdivision,
  onDragStart,
  onDragOver,
  onDrop,
  onImagesClick, // New prop for handling image click
}) {
  // Handler for clicking on the subdivision name
  const handleNameClick = () => {
    if (subdivision.images && subdivision.images.length > 0) {
      onImagesClick(subdivision.images);
    }
  };

  return (
    <TableRow
      key={subKey}
      draggable={editMode}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      sx={{ cursor: editMode ? 'grab' : 'default' }}
    >
      {editMode && (
        <TableCell>
          <IconButton
            sx={{ cursor: 'grab' }}
            onDragStart={(e) => e.stopPropagation()}
            aria-label="Drag Subdivision"
          >
            <DragHandleIcon />
          </IconButton>
        </TableCell>
      )}
      <TableCell
        onClick={!editMode ? handleNameClick : undefined}
        sx={{
          cursor: !editMode && subdivision.images && subdivision.images.length > 0 ? 'pointer' : 'default',
        }}
      >
        {editMode ? (
          <TextField
            variant="outlined"
            size="small"
            value={subdivision.name}
            onChange={(e) =>
              handleSubdivisionChange(divisionKey, subKey, 'name', e.target.value)
            }
          />
        ) : (
          subdivision.name
        )}
      </TableCell>
      <TableCell>
        {editMode ? (
          <TextField
            variant="outlined"
            size="small"
            value={subdivision.quantity}
            onChange={(e) =>
              handleSubdivisionChange(divisionKey, subKey, 'quantity', e.target.value)
            }
          />
        ) : (
          subdivision.quantity
        )}
      </TableCell>
      <TableCell>
        {editMode ? (
          <TextField
            variant="outlined"
            size="small"
            value={subdivision.Units}
            onChange={(e) =>
              handleSubdivisionChange(divisionKey, subKey, 'Units', e.target.value)
            }
          />
        ) : (
          subdivision.Units
        )}
      </TableCell>
      <TableCell>
        {editMode ? (
          <TextField
            variant="outlined"
            size="small"
            value={subdivision['Waste Factor']}
            onChange={(e) =>
              handleSubdivisionChange(divisionKey, subKey, 'Waste Factor', e.target.value)
            }
          />
        ) : (
          subdivision['Waste Factor']
        )}
      </TableCell>
      {editMode && (
        <TableCell>
          <IconButton
            onClick={() => handleDeleteSubdivision(divisionKey, subKey)}
            aria-label="Delete Subdivision"
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
});

// Memoized DivisionCard
const DivisionCard = React.memo(function DivisionCard({
  divisionKey,
  division,
  editMode,
  isExpanded,
  handleDivisionDragStart,
  handleDivisionDrop,
  handleDeleteDivision,
  handleDivisionChange,
  handleDivisionExpandToggle,
  handleAddSubdivision,
  handleSubdivisionChange,
  handleDeleteSubdivision,
  handleSubdivisionDragStart,
  handleSubdivisionDrop,
  handleSubdivisionImagesClick, // New prop passed down
}) {
  return (
    <Card
      key={divisionKey}
      draggable={editMode}
      onDragStart={(e) => handleDivisionDragStart(divisionKey)}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => handleDivisionDrop(divisionKey)}
      sx={{ mb: 2, cursor: editMode ? 'grab' : 'default' }}
    >
      <CardHeader
        avatar={
          editMode && (
            <IconButton
              sx={{ cursor: 'grab' }}
              onDragStart={(e) => e.stopPropagation()}
              aria-label="Drag Division"
            >
              <DragHandleIcon />
            </IconButton>
          )
        }
        action={
          editMode && (
            <IconButton
              onClick={() => handleDeleteDivision(divisionKey)}
              aria-label="Delete Division"
            >
              <DeleteIcon />
            </IconButton>
          )
        }
        title={
          editMode ? (
            <TextField
              variant="outlined"
              size="small"
              value={division.name}
              onChange={(e) => handleDivisionChange(divisionKey, 'name', e.target.value)}
            />
          ) : (
            <Typography
              variant="h6"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleDivisionExpandToggle(divisionKey)}
            >
              {division.name}
            </Typography>
          )
        }
        subheader={
          <IconButton
            onClick={() => handleDivisionExpandToggle(divisionKey)}
            aria-label="Toggle Expansion"
          >
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      />
      {isExpanded && (
        <>
          <Divider />
          <CardContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {editMode && <TableCell>Move</TableCell>}
                    <TableCell>Subdivision Name</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Units</TableCell>
                    <TableCell>Waste Factor</TableCell>
                    {editMode && <TableCell>Actions</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(division.subdivisions).map((subKey) => {
                    const subdivision = division.subdivisions[subKey];
                    return (
                      <SubdivisionRow
                        key={subKey}
                        editMode={editMode}
                        subdivision={subdivision}
                        subKey={subKey}
                        divisionKey={divisionKey}
                        handleSubdivisionChange={handleSubdivisionChange}
                        handleDeleteSubdivision={handleDeleteSubdivision}
                        onDragStart={(e) => handleSubdivisionDragStart(divisionKey, subKey)}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => handleSubdivisionDrop(divisionKey, subKey)}
                        onImagesClick={handleSubdivisionImagesClick}
                      />
                    );
                  })}
                  {editMode && (
                    <TableRow>
                      <TableCell colSpan={editMode ? 6 : 5}>
                        <Button
                          startIcon={<AddIcon />}
                          onClick={() => handleAddSubdivision(divisionKey)}
                        >
                          Add Subdivision
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </>
      )}
    </Card>
  );
});

function ValidationOutput() {
  const [tableData, setTableData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [expandedDivisions, setExpandedDivisions] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  // Snapshot states
  const [snapshotOpen, setSnapshotOpen] = useState(false);

  const { jobId } = useParams(); // Get the jobId from URL parameters

  // Chat states
  const [chatOpen, setChatOpen] = useState(false);
  const [chatPrompt, setChatPrompt] = useState('');
  const [chatMessages, setChatMessages] = useState([
    {
      role: 'assistant',
      message: 'Hello, how can I assist you today?',
      avatar: true,
    },
    {
      role: 'user',
      message: 'Can you tell me how many windows are there?',
      avatar: true,
    },
    {
      role: 'assistant',
      message:
        'Sure! There are 2 windows. First window is in Kitchen on the first floor and the second is in the bedroom on the second floor.',
      avatar: true,
    },
  ]);

  const [draggedDivision, setDraggedDivision] = useState(null);
  const [draggedSubdivision, setDraggedSubdivision] = useState({
    divisionKey: null,
    subKey: null,
  });

  // New states for image drawer
  const [imageDrawerOpen, setImageDrawerOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'error'
  });


  useEffect(() => {

    setTableData({}); // Reset table data when jobId changes

    async function fetchData() {
      if (!jobId) {
        console.error('No job ID provided');
        return;
      }

      const result = await getTakeoffData(jobId);
      if (result && !result.error) {
        setTableData(result);
      } else {
        console.error('Failed to load takeoff data:', result.error);
        // Import statement at the top of the file
        // Add this with other imports:

        // Add state for snackbar

        // Show error in snackbar
        const errorMessage = result.error || 'Failed to load takeoff data';
        setSnackbar({
          open: true,
          message: errorMessage,
          severity: 'error'
        });
      }
    }
    fetchData();
  }, [jobId]);


  const handleSnackbarClose = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  const handleEditToggle = useCallback(() => {
    setEditMode((prev) => !prev);
  }, []);

  const handleDivisionExpandToggle = useCallback((divisionKey) => {
    setExpandedDivisions((prev) => ({
      ...prev,
      [divisionKey]: !prev[divisionKey],
    }));
  }, []);

  const handleAddSubdivision = useCallback((divisionKey) => {
    const newSubdivisionKey = `Subdivision_${Date.now()}`;
    setTableData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[divisionKey].subdivisions[newSubdivisionKey] = {
        name: '',
        quantity: '',
        Units: '',
        'Waste Factor': '',
        images: [],
      };
      return updatedData;
    });
  }, []);

  const handleDeleteSubdivision = useCallback((divisionKey, subdivisionKey) => {
    setTableData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[divisionKey].subdivisions[subdivisionKey];
      return updatedData;
    });
  }, []);

  const handleSubdivisionChange = useCallback((divisionKey, subdivisionKey, field, value) => {
    setTableData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[divisionKey].subdivisions[subdivisionKey][field] = value;
      return updatedData;
    });
  }, []);

  const handleDivisionChange = useCallback((divisionKey, field, value) => {
    setTableData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[divisionKey][field] = value;
      return updatedData;
    });
  }, []);

  const handleAddDivision = useCallback(() => {
    const newDivisionKey = `Division_${Date.now()}`;
    setTableData((prevData) => ({
      ...prevData,
      [newDivisionKey]: {
        name: '',
        images: [],
        subdivisions: {},
      },
    }));
  }, []);

  const handleDeleteDivision = useCallback((divisionKey) => {
    setTableData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[divisionKey];
      return updatedData;
    });

    setExpandedDivisions((prev) => {
      const newExpanded = { ...prev };
      delete newExpanded[divisionKey];
      return newExpanded;
    });
  }, []);

  const filteredDivisions = useMemo(() => {
    return Object.keys(tableData).filter((divisionKey) => {
      const division = tableData[divisionKey];
      const query = searchQuery.toLowerCase();
      const divisionMatch = division.name.toLowerCase().includes(query);
      const subdivisionMatch = Object.values(division.subdivisions).some((subdivision) =>
        subdivision.name.toLowerCase().includes(query)
      );
      return divisionMatch || subdivisionMatch;
    });
  }, [tableData, searchQuery]);

  const handleSnapshotOpen = useCallback(() => {
    setSnapshotOpen(true);
    setChatOpen(false);
  }, []);

  const handleSnapshotClose = useCallback(() => {
    setSnapshotOpen(false);
  }, []);

  const handleChatToggle = useCallback(() => {
    setChatOpen((prev) => {
      if (!prev) setSnapshotOpen(false);
      return !prev;
    });
  }, [setSnapshotOpen]);

  const handleChatSend = useCallback(async () => {
    if (!chatPrompt.trim()) return;
    const userMessage = {
      role: 'user',
      message: chatPrompt,
      timestamp: new Date().toLocaleString(),
      avatar: true,
    };
    setChatMessages((msgs) => [...msgs, userMessage]);

    // Simulate backend call
    const fileUrl = '';
    const response = await createJob(chatPrompt, fileUrl);

    const assistantMessage = {
      role: 'assistant',
      message: response?.error
        ? `Error: ${response.error}`
        : 'This is a response from the assistant!',
      timestamp: new Date().toLocaleString(),
      avatar: true,
    };
    setChatMessages((msgs) => [...msgs, assistantMessage]);

    setChatPrompt('');
  }, [chatPrompt]);

  // Handle Division Drag
  const handleDivisionDragStart = useCallback((divisionKey) => {
    setDraggedDivision(divisionKey);
  }, []);

  const handleDivisionDrop = useCallback(
    (targetDivisionKey) => {
      if (draggedDivision === null || draggedDivision === targetDivisionKey) return;

      const divisionKeys = filteredDivisions;
      const draggedIndex = divisionKeys.indexOf(draggedDivision);
      const targetIndex = divisionKeys.indexOf(targetDivisionKey);

      if (draggedIndex === -1 || targetIndex === -1) return;

      const reorderedKeys = Array.from(divisionKeys);
      reorderedKeys.splice(draggedIndex, 1);
      reorderedKeys.splice(targetIndex, 0, draggedDivision);

      const reorderedData = {};
      reorderedKeys.forEach((key) => {
        reorderedData[key] = tableData[key];
      });
      setTableData(reorderedData);
      setDraggedDivision(null);
    },
    [draggedDivision, filteredDivisions, tableData]
  );

  // Handle Subdivision Drag
  const handleSubdivisionDragStart = useCallback((divisionKey, subKey) => {
    setDraggedSubdivision({ divisionKey, subKey });
  }, []);

  const handleSubdivisionDrop = useCallback(
    (targetDivisionKey, targetSubKey) => {
      const { divisionKey: sourceDivisionKey, subKey: sourceSubKey } = draggedSubdivision;

      // Ensure dragging within the same division
      if (sourceDivisionKey !== targetDivisionKey) return;

      const sourceSubdivisions = Object.keys(tableData[sourceDivisionKey].subdivisions);
      const targetIndex = sourceSubdivisions.indexOf(targetSubKey);
      const sourceIndex = sourceSubdivisions.indexOf(sourceSubKey);

      if (sourceIndex === -1 || targetIndex === -1 || sourceSubKey === targetSubKey) return;

      // Reorder subdivisions
      const reorderedSubKeys = Array.from(sourceSubdivisions);
      reorderedSubKeys.splice(sourceIndex, 1);
      reorderedSubKeys.splice(targetIndex, 0, sourceSubKey);

      setTableData((prevData) => {
        const updatedData = { ...prevData };
        const reorderedSubdivisions = {};
        reorderedSubKeys.forEach((key) => {
          reorderedSubdivisions[key] = updatedData[sourceDivisionKey].subdivisions[key];
        });
        updatedData[sourceDivisionKey].subdivisions = reorderedSubdivisions;
        return updatedData;
      });

      setDraggedSubdivision({ divisionKey: null, subKey: null });
    },
    [draggedSubdivision, tableData]
  );

  // Handle images click
  const handleSubdivisionImagesClick = useCallback((images) => {
    setSelectedImages(images);
    setImageDrawerOpen(true);
  }, []);

  const handleImageDrawerClose = useCallback(() => {
    setImageDrawerOpen(false);
    setSelectedImages([]);
  }, []);

  return (
    <MainLayout>
      <Box sx={{ paddingLeft: 4, scrollPaddingTop: 4, display: 'flex', gap: 1, height: '100vh', ml: 30 }}>
        {/* Main Content */}
        <Box sx={{ flex: 1, minWidth: 0, overflowY: 'auto' }}>
          <Typography variant="h4" gutterBottom>
            Validation Output
          </Typography>

          <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>


          <Toolbar disableGutters sx={{ p: 0, mb: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon sx={{ mr: 1 }} />,
              }}
              sx={{ mr: 2 }}
            />
            <Button
              variant="contained"
              startIcon={editMode ? <SaveIcon /> : <EditIcon />}
              onClick={handleEditToggle}
              sx={{ mr: 2 }}
            >
              {editMode ? 'Save' : 'Edit'}
            </Button>
            {editMode && (
              <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddDivision}>
                Add Division
              </Button>
            )}
          </Toolbar>

          {/* Display Divisions */}
          <div>
            {filteredDivisions.map((divisionKey) => {
              const division = tableData[divisionKey];
              const isExpanded = expandedDivisions[divisionKey] || false;

              return (
                <DivisionCard
                  key={divisionKey}
                  divisionKey={divisionKey}
                  division={division}
                  editMode={editMode}
                  isExpanded={isExpanded}
                  handleDivisionDragStart={handleDivisionDragStart}
                  handleDivisionDrop={handleDivisionDrop}
                  handleDeleteDivision={handleDeleteDivision}
                  handleDivisionChange={handleDivisionChange}
                  handleDivisionExpandToggle={handleDivisionExpandToggle}
                  handleAddSubdivision={handleAddSubdivision}
                  handleSubdivisionChange={handleSubdivisionChange}
                  handleDeleteSubdivision={handleDeleteSubdivision}
                  handleSubdivisionDragStart={handleSubdivisionDragStart}
                  handleSubdivisionDrop={handleSubdivisionDrop}
                  handleSubdivisionImagesClick={handleSubdivisionImagesClick}
                />
              );
            })}
          </div>
        </Box>

        {/* Previews Container */}
        <Box
          sx={{
            width: 300,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            height: '100%',
          }}
        >
          {/* Snapshot Preview */}
          <Box sx={{ flex: 0.7, width: '100%' }}>
            <SnapshotPreview onOpen={handleSnapshotOpen} />
          </Box>

          {/* Chat Preview */}
          <Box sx={{ flex: 1, width: '100%' }}>
            <ChatPreview onOpen={handleChatToggle} />
          </Box>
        </Box>
      </Box>

      {/* Snapshot Drawer */}
      <Drawer
        anchor="right"
        open={snapshotOpen}
        onClose={handleSnapshotClose}
        PaperProps={{
          sx: { width: '700px', maxWidth: '90vw' },
        }}
      >
        <Box sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'primary.main',
              color: 'white',
              p: 1,
            }}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Snapshot
            </Typography>
            <IconButton onClick={handleSnapshotClose} sx={{ color: 'white' }} aria-label="Close Snapshot Drawer">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ p: 2, overflowY: 'auto', flexGrow: 1 }}>
            <img
              src={SnapshotImage}
              alt="Snapshot Enlarged"
              style={{ width: '100%', borderRadius: '8px' }}
            />
          </Box>
        </Box>
      </Drawer>

      {/* Chat Drawer */}
      <Drawer
        anchor="right"
        open={chatOpen}
        onClose={handleChatToggle}
        PaperProps={{
          sx: { width: '500px', maxWidth: '90vw' },
        }}
      >
        <Box sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
          {/* Chat Header */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'primary.main',
              color: 'white',
              p: 1,
            }}
          >
            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
              AI Assistant
            </Typography>
            <IconButton onClick={handleChatToggle} sx={{ color: 'white' }} aria-label="Close Chat Drawer">
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Chat Body */}
          <Box sx={{ flexGrow: 1, p: 2, overflowY: 'auto' }}>
            {chatMessages.map((msg, index) =>
              msg.role === 'assistant' ? (
                <MessageLeft
                  key={index}
                  message={msg.message}
                  timestamp={msg.timestamp}
                  avatarDisp={msg.avatar}
                />
              ) : (
                <MessageRight
                  key={index}
                  message={msg.message}
                  timestamp={msg.timestamp}
                  avatarDisp={msg.avatar}
                />
              )
            )}
          </Box>

          {/* Chat Input */}
          <TextInput value={chatPrompt} onChange={setChatPrompt} onSend={handleChatSend} />
        </Box>
      </Drawer>

      {/* Images Drawer for subdivisions */}
      <Drawer
        anchor="right"
        open={imageDrawerOpen}
        onClose={handleImageDrawerClose}
        PaperProps={{
          sx: { width: '600px', maxWidth: '90vw' },
        }}
      >
        <Box sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'primary.main',
              color: 'white',
              p: 1,
            }}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Images
            </Typography>
            <IconButton onClick={handleImageDrawerClose} sx={{ color: 'white' }} aria-label="Close Images Drawer">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ p: 2, overflowY: 'auto', flexGrow: 1, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            {selectedImages.map((imgUrl, idx) => (
              <Box key={idx} sx={{ maxWidth: '100%', borderRadius: 2, overflow: 'hidden' }}>
                <img
                  src={imgUrl}
                  alt={`Subdivision Image ${idx + 1}`}
                  style={{ maxWidth: '100%', borderRadius: '8px' }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Drawer>
    </MainLayout>
  );
}

export default ValidationOutput;
