import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Tabs,
  Tab,
  Card,
  CardContent,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { AuthContext } from '../../context/authContext';
import MainLayout from '../MainLayout';
import {
  Description as DescriptionIcon,
  AttachMoney as AttachMoneyIcon,
  Business as BusinessIcon,
  Folder as FolderIcon,
  Group as GroupIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

function Profile() {
  const { user } = useContext(AuthContext);
  const [username, setUsername] = useState(user?.username || '');
  const [email, setEmail] = useState(user?.email || '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [isAdmin, setIsAdmin] = useState(user?.isAdmin || true);
  const [selectedUserEmail, setSelectedUserEmail] = useState('john.doe@company.com');
  const [availableUsers] = useState(['john.doe@company.com', 'jane.smith@company.com']);
  const [tabIndex, setTabIndex] = useState(0);
  const [userPermissions, setUserPermissions] = useState({});

  const categories = [
    {
      label: 'Proposals',
      icon: <DescriptionIcon />,
      groups: [
        {
          title: 'Proposal Creation & Management',
          permissions: [
            { id: 'create_proposal', label: 'Create new proposals', defaultChecked: true },
            { id: 'edit_proposal', label: 'Edit existing proposals', defaultChecked: true },
            { id: 'delete_proposal', label: 'Delete proposals', defaultChecked: false },
            { id: 'approve_proposal', label: 'Approve proposals', defaultChecked: false },
            { id: 'send_proposal', label: 'Send proposals to clients', defaultChecked: true },
            { id: 'duplicate_proposal', label: 'Duplicate existing proposals', defaultChecked: true },
          ],
        },
        {
          title: 'Proposal Templates',
          permissions: [
            { id: 'create_proposal_template', label: 'Create proposal templates', defaultChecked: false },
            { id: 'edit_proposal_template', label: 'Edit proposal templates', defaultChecked: false },
            { id: 'use_proposal_template', label: 'Use existing templates', defaultChecked: true },
          ],
        },
      ],
    },
    {
      label: 'Cost Catalog',
      icon: <AttachMoneyIcon />,
      groups: [
        {
          title: 'Cost Catalog Management',
          permissions: [
            { id: 'view_costs', label: 'View cost catalog', defaultChecked: true },
            { id: 'add_costs', label: 'Add new cost items', defaultChecked: false },
            { id: 'edit_costs', label: 'Edit cost items', defaultChecked: false },
            { id: 'delete_costs', label: 'Delete cost items', defaultChecked: false },
            { id: 'import_costs', label: 'Import cost data', defaultChecked: false },
            { id: 'export_costs', label: 'Export cost data', defaultChecked: true },
          ],
        },
        {
          title: 'Pricing Rules',
          permissions: [
            { id: 'view_pricing_rules', label: 'View pricing rules', defaultChecked: true },
            { id: 'edit_pricing_rules', label: 'Edit pricing rules', defaultChecked: false },
            { id: 'create_pricing_rules', label: 'Create new pricing rules', defaultChecked: false },
          ],
        },
      ],
    },
    {
      label: 'Company',
      icon: <BusinessIcon />,
      groups: [
        {
          title: 'Company Profile Management',
          permissions: [
            { id: 'view_company_profile', label: 'View company profile', defaultChecked: true },
            { id: 'edit_company_info', label: 'Edit company information', defaultChecked: false },
            { id: 'manage_logos', label: 'Manage company logos', defaultChecked: false },
            { id: 'manage_documents', label: 'Manage company documents', defaultChecked: false },
          ],
        },
        {
          title: 'Brand Settings',
          permissions: [
            { id: 'view_brand_settings', label: 'View brand settings', defaultChecked: true },
            { id: 'edit_brand_settings', label: 'Edit brand settings', defaultChecked: false },
            { id: 'manage_templates', label: 'Manage brand templates', defaultChecked: false },
          ],
        },
      ],
    },
    {
      label: 'Projects',
      icon: <FolderIcon />,
      groups: [
        {
          title: 'Project Management',
          permissions: [
            { id: 'create_project', label: 'Create new projects', defaultChecked: true },
            { id: 'delete_project', label: 'Delete projects', defaultChecked: false },
            { id: 'edit_settings', label: 'Edit project settings', defaultChecked: true },
          ],
        },
      ],
    },
    {
      label: 'Team',
      icon: <GroupIcon />,
      groups: [
        {
          title: 'Team Management',
          permissions: [
            { id: 'invite_members', label: 'Invite team members', defaultChecked: true },
            { id: 'remove_members', label: 'Remove team members', defaultChecked: false },
            { id: 'assign_roles', label: 'Assign member roles', defaultChecked: true },
          ],
        },
      ],
    },
    {
      label: 'Content',
      icon: <InsertDriveFileIcon />,
      groups: [
        {
          title: 'File Management',
          permissions: [
            { id: 'upload_files', label: 'Upload files', defaultChecked: true },
            { id: 'delete_files', label: 'Delete files', defaultChecked: true },
            { id: 'share_files', label: 'Share files externally', defaultChecked: false },
          ],
        },
      ],
    },
    {
      label: 'Admin',
      icon: <SettingsIcon />,
      groups: [
        {
          title: 'Administrative Access',
          permissions: [
            { id: 'system_settings', label: 'Access system settings', defaultChecked: false },
            { id: 'billing_access', label: 'View and manage billing', defaultChecked: false },
            { id: 'audit_logs', label: 'Access audit logs', defaultChecked: false },
          ],
        },
      ],
    },
  ];

  const initializeUserPermissions = (userEmail) => {
    const initialPermissions = {};
    categories.forEach((category) => {
      category.groups.forEach((group) => {
        group.permissions.forEach((permission) => {
          initialPermissions[`${category.label}.${permission.id}`] = permission.defaultChecked;
        });
      });
    });
    setUserPermissions(prev => ({
      ...prev,
      [userEmail]: initialPermissions
    }));
  };

  useEffect(() => {
    if (selectedUserEmail && !userPermissions[selectedUserEmail]) {
      initializeUserPermissions(selectedUserEmail);
    }
  }, [selectedUserEmail]);

  const handlePermissionChange = (category, permissionId) => (event) => {
    setUserPermissions(prev => ({
      ...prev,
      [selectedUserEmail]: {
        ...prev[selectedUserEmail],
        [`${category}.${permissionId}`]: event.target.checked
      }
    }));
  };

  const getPermissionValue = (category, permissionId) => {
    if (!userPermissions[selectedUserEmail]) return false;
    return userPermissions[selectedUserEmail][`${category}.${permissionId}`] || false;
  };

  const handleUserChange = (event) => {
    const newUserEmail = event.target.value;
    setSelectedUserEmail(newUserEmail);
    if (!userPermissions[newUserEmail]) {
      initializeUserPermissions(newUserEmail);
    }
  };

  const handleSavePermissions = () => {
    console.log('Saving permissions for user:', selectedUserEmail, userPermissions[selectedUserEmail]);
    // Add API call to save permissions here
  };

  const handleProfileSubmit = (e) => {
    e.preventDefault();
    // Handle profile update logic here
    console.log('Profile update submitted:', { username, email, password, confirmPassword });
  };

  return (
    <MainLayout>
      <Box sx={{ maxWidth: '1200px', margin: 'auto', padding: 2 }}>
        <Typography variant="h4" gutterBottom>
          Profile Settings
        </Typography>
        
        {/* Profile Section */}
        <Card sx={{ marginBottom: 4 }}>
          <CardContent>
            <form onSubmit={handleProfileSubmit}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <Avatar
                  src={profileImage}
                  sx={{ width: 80, height: 80, marginRight: 2 }}
                />
                <Button variant="outlined" component="label">
                  Upload Profile Image
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => setProfileImage(URL.createObjectURL(e.target.files[0]))}
                  />
                </Button>
              </Box>
              <TextField
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
                type="email"
              />
              <TextField
                label="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="normal"
                type="password"
              />
              <TextField
                label="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                margin="normal"
                type="password"
              />
              <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
                Save Changes
              </Button>
            </form>
          </CardContent>
        </Card>

        {/* Permissions Section */}
        {isAdmin && (
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Manage User Permissions
              </Typography>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Select User</InputLabel>
                <Select
                  value={selectedUserEmail}
                  onChange={handleUserChange}
                  label="Select User"
                >
                  {availableUsers.map((userEmail) => (
                    <MenuItem key={userEmail} value={userEmail}>
                      {userEmail}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Tabs
                value={tabIndex}
                onChange={(event, newValue) => setTabIndex(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ marginBottom: 2 }}
              >
                {categories.map((category) => (
                  <Tab
                    key={category.label}
                    label={category.label}
                    icon={category.icon}
                    iconPosition="start"
                  />
                ))}
              </Tabs>

              {categories.map((category, index) => (
                <div key={category.label} hidden={tabIndex !== index}>
                  {category.groups.map((group) => (
                    <Card key={group.title} sx={{ marginBottom: 2 }}>
                      <CardContent>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                          {group.title}
                        </Typography>
                        {group.permissions.map((permission) => (
                          <FormControlLabel
                            key={permission.id}
                            control={
                              <Switch
                                checked={getPermissionValue(category.label, permission.id)}
                                onChange={handlePermissionChange(category.label, permission.id)}
                              />
                            }
                            label={permission.label}
                            sx={{ display: 'block' }}
                          />
                        ))}
                      </CardContent>
                    </Card>
                  ))}
                </div>
              ))}

              <Button 
                variant="contained" 
                color="primary" 
                sx={{ mt: 2 }}
                onClick={handleSavePermissions}
              >
                Save Permissions
              </Button>
            </CardContent>
          </Card>
        )}
      </Box>
    </MainLayout>
  );
}

export default Profile;